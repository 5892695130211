import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getUsers = createAsyncThunk(
  "users/getUsers",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
      .get("/admin/organiztionusers/")
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }
    
      
  }
);

export const updateUserStatus = createAsyncThunk(
  "organiztion/updateUserStatus",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post(`/admin/organiztionusers/changeStatus`, params);
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const changeUserPassword = createAsyncThunk(
  "organiztion/changeUserPassword",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
      .post(`/admin/changeUserPassword`, params)
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }

     
  }
);
