import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getOnboardingQuestions = createAsyncThunk(
  "onboardingQuestions/getQuestions",
  async (thunkAPI) => {
    try {
      const response = await secureApi
        .get("/admin/onBoardingQuestions/");
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const getSingleQuestionData = createAsyncThunk(
  "onboardingQuestions/getSingleQuestionData",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .get(`/admin/onBoardingQuestions/${id}`);
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }

  }
);

export const addOnBoardingQuestion = createAsyncThunk(
  "onboardingQuestions/addOnBoardingQuestion",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post("/admin/onBoardingQuestions/", params)
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }

  }
);

export const editOnBoardingQuestion = createAsyncThunk(
  "onboardingQuestions/editOnBoardingQuestion",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .patch(`/admin/onBoardingQuestions/${params.id}`, params)
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }

  }
);

export const deleteOnBoardingQuestion = createAsyncThunk(
  "onboardingQuestions/deleteOnBoardingQuestion",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .delete(`/admin/onBoardingQuestions/${id}`)
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const askOnbordingQuestionaAgain = createAsyncThunk(
  "onboardingQuestions/askOnbordingQuestionaAgain",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post("/admin/onboardingQuestions/askagain", params)
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const getOnboardingAnswers = createAsyncThunk(
  "onboardingQuestions/getOnboardingAnswers",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/respondent/getonboardingAnswer/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data.status) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const updateOnboardingQuestionsOrder = createAsyncThunk(
  "onboardingQuestions/updateOrder",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post("/admin/onboardingQuestions/updateOrder", params)
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }



  }
);
