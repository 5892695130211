import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getAllRespondents = createAsyncThunk(
  "respondents/getAllRespondents",
  async (thunkAPI) => {
    try {
      const response = await secureApi
        .get("/admin/respondent/getall");
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const sendNotice = createAsyncThunk(
  "respondents/sendNotice",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post("/admin/sendNotification/notify", params);
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const changeRespondentPassword = createAsyncThunk(
  "respondents/changeRespondentPassword",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post("/admin/changeRespondentPassword", params)
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const getFilledSurveys = createAsyncThunk(
  "respondents/getFilledSurveys",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post("/admin/respondent/getsurveys", params);
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const changeRespondentStatus = createAsyncThunk(
  "respondents/changeRespondentStatus",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post("/admin/respondent/changerespondentstatus", params)
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const getFinalRespondents = createAsyncThunk(
  "respondents/getFinalRespondents",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .post("/admin/livesurvey/getRespondentsForLiveSurvey", {
          surveyId: id,
        })
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const getRespondentProfile = createAsyncThunk(
  "respondents/getprofile",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi.get(
        `/admin/respondent/getprofile/${id}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);
export const getMetaForRespondent = createAsyncThunk(
  "respondents/getMetaForRespondent",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi.get(
        `/admin/researchMeta/getMetaForRespondent/${id}`
      );
      return response.data;
    } catch (error) {
      return error;
    }
  }
);

export const getCompletedResearchProjects = createAsyncThunk(
  "respondents/getCompletedResearchProjects",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post("/admin/respondent/getresearchprojects", params)
      return response.data;
    } catch (error) {
      return error;
    }


  }
);
