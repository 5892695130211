import React, { Component } from "react";
import { SettingOutlined, LogoutOutlined } from "@ant-design/icons";
import { Drawer, Menu, Button, Modal, Form, Input, message } from "antd";
import ThemeConfigurator from "./ThemeConfigurator";
import { connect } from "react-redux";
import { DIR_RTL } from "../../constants/ThemeConstant";
import { withRouter } from "react-router-dom";
import { changeAdminPassword } from "../../redux/toolkit/auth/authThunk";
import APIstatus from "../../redux/constants/common";
class NavPanel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      visible: false,
      passwordModalVisible: false,
      oldPassword: "",
      newPass: "",
      cnfNewPass: "",
      isSubmitted: false,
    };
  }

  componentDidUpdate = (prevProps) => {
    if (
      prevProps.changeAdminPasswordRes.status !==
        this.props.changeAdminPasswordRes.status &&
      this.props.changeAdminPasswordRes.status === APIstatus.SUCCESS
    ) {
      const res = this.props.changeAdminPasswordRes?.data;

      if (res?.status) {
        this.setState({
          isSubmitted: false,
          visible: false,
          passwordModalVisible: false,
          oldPassword: "",
          newPass: "",
          cnfNewPass: "",
        });
        message.success(res?.message);
      }
    } else if (
      prevProps.changeAdminPasswordRes.status !==
        this.props.changeAdminPasswordRes.status &&
      this.props.changeAdminPasswordRes.status === APIstatus.FAILURE
    ) {
      message.error("There is something went wrong");
    }
  };

  validate = (isSubmitted) => {
    const { oldPassword, newPass, cnfNewPass } = this.state;
    let isValid = true;
    const retObj = {
      oldPassword: {
        message: "",
        isValid: false,
      },
      newPass: {
        message: "",
        isValid: false,
      },
      cnfNewPass: {
        message: "",
        isValid: false,
      },
      isValid,
    };

    if (isSubmitted) {
      if (!oldPassword) {
        retObj.oldPassword.message = "Please enter your current password.";
        retObj.oldPassword.isValid = false;
        retObj.isValid = false;
      }

      if (!newPass) {
        retObj.newPass.message = "Please enter a new password.";
        retObj.newPass.isValid = false;
        retObj.isValid = false;
      }

      if (!cnfNewPass) {
        retObj.cnfNewPass.message = "Please confirm your new password.";
        retObj.cnfNewPass.isValid = false;
        retObj.isValid = false;
      } else if (cnfNewPass !== newPass) {
        retObj.cnfNewPass.message =
          "New password and confirm password do not match.";
        retObj.cnfNewPass.isValid = false;
        retObj.isValid = false;
      }
    }

    return retObj;
  };
  showDrawer = () => {
    this.setState({ visible: true });
  };

  onClose = () => {
    this.setState({ visible: false });
  };

  signOut = () => {
    localStorage.removeItem("authToken");
    this.props.history.push("/auth/login");
  };

  showPasswordModal = () => {
    this.setState({
      passwordModalVisible: true,
      oldPassword: "",
      newPass: "",
      cnfNewPass: "",
    });
  };

  handlePasswordModalCancel = () => {
    this.setState({ passwordModalVisible: false });
  };

  handlePasswordSubmit = (values) => {
    const { oldPassword, newPass, cnfNewPass } = this.state;
    this.setState({ isSubmitted: true });
    const validate = this.validate(true);
    if (validate.isValid) {
      this.props.changeAdminPassword({
        oldPassword: oldPassword,
        newPassword: newPass,
      });
    }
  };

  handleChange = (e) => {
    const { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    const { oldPassword, newPass, cnfNewPass, isSubmitted } = this.state;
    const validate = this.validate(isSubmitted);
    return (
      <>
        <Menu mode="horizontal">
          <Menu.Item key="changePassword">
            <Button onClick={this.showPasswordModal}>Change Password</Button>
          </Menu.Item>
          <Menu.Item key="logout" onClick={this.signOut}>
            <LogoutOutlined className="nav-icon mr-2" />
            Sign Out
          </Menu.Item>
          <Menu.Item key="panel" onClick={this.showDrawer}>
            <SettingOutlined className="nav-icon mr-2" />
            Setting
          </Menu.Item>
        </Menu>
        <Drawer
          title="Theme Config"
          placement={this.props.direction === DIR_RTL ? "left" : "right"}
          width={350}
          onClose={this.onClose}
          open={this.state.visible}
        >
          <ThemeConfigurator />
        </Drawer>
        <Modal
          title="Change Password"
          open={this.state.passwordModalVisible}
          onCancel={this.handlePasswordModalCancel}
          footer={null}
        >
          <Form layout="vertical">
            <Form.Item
              className="mb-3"
              label=" Old Password"
              type="password"
              name="oldPassword"
              value={oldPassword}
              onChange={this.handleChange}
            >
              <Input.Password />
              {isSubmitted && (
                <span style={{ color: "red" }}>
                  {validate.oldPassword.message}
                </span>
              )}
            </Form.Item>
            <Form.Item
              className="mb-3"
              label="New Password"
              type="password"
              name="newPass"
              value={newPass}
              onChange={this.handleChange}
            >
              <Input.Password />
              {isSubmitted && (
                <span style={{ color: "red" }}>{validate.newPass.message}</span>
              )}
            </Form.Item>
            <Form.Item
              className="mb-3"
              label=" Confirm New Password"
              type="password"
              name="cnfNewPass"
              value={cnfNewPass}
              onChange={this.handleChange}
            >
              <Input.Password />
              {isSubmitted && (
                <span style={{ color: "red" }}>
                  {validate.cnfNewPass.message}
                </span>
              )}
            </Form.Item>
            <Button
              onClick={this.handlePasswordSubmit}
              type="primary"
              className="w-100"
              disabled={
                this.props.changeAdminPasswordRes.status ===
                APIstatus.IN_PROGRESS
              }
              loading={
                this.props.changeAdminPasswordRes.status ===
                APIstatus.IN_PROGRESS
              }
            >
              Change Password
            </Button>
          </Form>
        </Modal>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  const { locale } = state.theme;
  const { changeAdminPasswordRes } = state.auth;
  return { locale, changeAdminPasswordRes };
};

const mapDispatchToProps = {
  changeAdminPassword,
};

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(NavPanel)
);
