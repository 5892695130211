import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  getJobFunction,
  addJobFunction,
  updateJobFunction,
  deleteJobFunction,
} from "./jobFunctionThunk";

const initialState = {
  getJobFunctionRes: { status: "", data: null },
  addJobFunctionRes: { status: "", data: null },
  updateJobFunctionRes: { status: "", data: null },
  deleteJobFunctionRes: { status: "", data: null },
};

export const jobFunctionSlice = createSlice({
  name: "jobFunction",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJobFunction.pending.toString(), (state) => {
      return {
        ...state,
        getJobFunctionRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getJobFunction.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getJobFunctionRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getJobFunction.rejected.toString(), (state) => {
      return {
        ...state,
        getJobFunctionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addJobFunction.pending.toString(), (state) => {
      return {
        ...state,
        addJobFunctionRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addJobFunction.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addJobFunctionRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(addJobFunction.rejected.toString(), (state) => {
      return {
        ...state,
        addJobFunctionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateJobFunction.pending.toString(), (state) => {
      return {
        ...state,
        updateJobFunctionRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updateJobFunction.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateJobFunctionRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(updateJobFunction.rejected.toString(), (state) => {
      return {
        ...state,
        updateJobFunctionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
    builder.addCase(deleteJobFunction.pending.toString(), (state) => {
      return {
        ...state,
        deleteJobFunctionRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteJobFunction.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteJobFunctionRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(deleteJobFunction.rejected.toString(), (state) => {
      return {
        ...state,
        deleteJobFunctionRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
