import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getSeniorityLevel = createAsyncThunk(
  "seniorityLevel/getSeniorityLevel",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .get(`/admin/researchMeta/getAllSeniorityLevel`)
      return response.data;
    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const addSeniorityLevel = createAsyncThunk(
  "seniorityLevel/addSeniorityLevel",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post(`/admin/researchMeta/createSeniorityLevel`, params);
      return response.data;
    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const updateSeniorityLevel = createAsyncThunk(
  "seniorityLevel/updateSeniorityLevel",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .patch(`/admin/researchMeta/editSeniorityLevel/${params.id}`, {
          seniorityLevel: params.seniorityLevel,
        })
      return response.data;
    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const deleteSeniorityLevel = createAsyncThunk(
  "seniorityLevel/deleteSeniorityLevel",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .delete(`/admin/researchMeta/deleteSeniorityLevel/${id}`)
      return response.data;
    } catch (error) {
      return { ...error, status: false }
    }
  }
);
