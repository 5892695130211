import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getResearchProjectsByOrg = createAsyncThunk(
  "researchMeta/getResearchProjectsByOrg",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/researchProject/getResearchProjectsByOrg/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const changeResearchProjectStatus = createAsyncThunk(
  "researchMeta/changeResearchProjectStatus",
  async (params, thunkAPI) => {
    const response = await secureApi
      .post(`/admin/researchProject/changeResearchProjectStatus`, {
        projectId: params.id,
        status: params.status,
      })
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getIndividualProject = createAsyncThunk(
  "researchMeta/getIndividualProject",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/researchProject/getIndividualProject/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getAllResearchMeta = createAsyncThunk(
  "researchMeta/getAllResearchMeta",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/researchMeta/getAllMeta`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const runResearchProjectMatchingAlgo = createAsyncThunk(
  "researchProject/runResearchProjectMatchingAlgo",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .post(`/admin/researchProject/runMatchingAlgo`, { projectId: id });
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }



  }
);

export const getResearchProjectRespondents = createAsyncThunk(
  "researchMeta/getResearchProjectRespondents",
  async (id, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/researchProject/getRespondentsForProject/${id}`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const getAllLiveResearchProjects = createAsyncThunk(
  "researchMeta/getAllLiveResearchProjects",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .get(`/admin/researchProject/getAllLiveResearchProjects`);
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const getScreenedQuestionForRespondent = createAsyncThunk(
  "getScreenedQuestionForRespondent",
  async (params, thunkAPI) => {
    try {

      const url = `/admin/researchProject/getScreenedQuestionForRespondent/${params.respondentId}/${params.projectId}`;
      const response = await secureApi.get(url, {
        params
      });

      return {
        data: response.data,
        status: response.status
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error.response.data);

    }
  }
);


export const getAllMeta = createAsyncThunk("getAllMeta", async () => {
  try {
    const url = `/admin/researchMeta/getAllMeta`;
    const response = await secureApi.get(url);
    return {
      data: response.data,
      status: response.status
    }
  } catch (error) {
    throw error.response?.data;
  }
});
export const getMetaForRespondent = createAsyncThunk("getMetaForRespondent", async (id) => {
  try {
    const url = `/admin/researchMeta/getMetaForRespondent/${id}`;
    const response = await secureApi.get(url);
    return {
      data: response.data,
      status: response.status
    }
  } catch (error) {
    throw error.response?.data;
  }
});