import axios from "axios";
import tokenService from "./tokenService";
import { message, notification } from "antd";
import Utils from "../utils";
import { env } from "../configs/EnvironmentConfig";

function getAuth() {
  return `Bearer ${tokenService.getToken()}`;
}

const secureApi = axios.create({
  baseURL: env.baseURL,
  headers: {
    Authorization: getAuth(),
  },
});

// Add a request interceptor
secureApi.interceptors.request.use(
  function (config) {
    // Uncomment and adjust these lines based on your specific requirements
    // if (config.method === "post") {
    //   if (config.data.append) {
    //     // This means it is formdata
    //     config.data.append("version_code", "web_version");
    //   } else {
    //     config.data = {
    //       ...config.data,
    //       version_code: "web_version",
    //     };
    //   }
    // } else {
    //   config.params = {
    //     ...config.params,
    //     version_code: "web_version",
    //   };
    // }
    config.headers.Authorization = getAuth();
    return config;
  },
  function (error) {

    return Promise.reject(error);
  }
);

// Add a response interceptor
secureApi.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      tokenService.deleteToken();
      Utils.handleLogout();
      document.location.replace(document.location.origin + "/auth/login");
      notification.error({ message: "User Unauthorized." });
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      tokenService.deleteToken();
      Utils.handleLogout();
      notification.error({ message: "User Unauthorized." });
      setTimeout(() => {
        document.location.replace(
          document.location.origin + "/auth/prelogin/login"
        );
      }, 1000);
    }

    if (error.response && error.response.data) {
      message.error(error.response?.data?.message || "Internal Server Error")

      return Promise.reject(error.response.data);
    }
    message.error(error.message || "Internal Server Error")
    return Promise.reject(error.message);
  }
);

export const api = axios.create({
  baseURL: env.baseURL,
});

api.interceptors.request.use(
  function (config) {
    // Uncomment and adjust these lines based on your specific requirements
    // if (config.method === "post") {
    //   if (config.data.append) {
    //     // This means it is formdata
    //     config.data.append("version_code", "web_version");
    //   } else {
    //     config.data = {
    //       ...config.data,
    //       version_code: "web_version",
    //     };
    //   }
    // } else {
    //   config.params = {
    //     ...config.params,
    //     version_code: "web_version",
    //   };
    // }
    return config;
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error);
  }
);

api.interceptors.response.use(
  (response) => {
    if (response.status === 401) {
      tokenService.deleteToken();
    }
    return response;
  },
  (error) => {
    if (error.response && error.response.status === 401) {
      tokenService.deleteToken();
    }
    if (error.response && error.response.data) {
      return Promise.reject(error.response.data);
    }
    return Promise.reject(error.message);
  }
);

export default secureApi;
