import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getCompanySize = createAsyncThunk(
  "companySize/getCompanySize",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .get(`/admin/researchMeta/getAllCompanySize`)
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const addCompanySize = createAsyncThunk(
  "companySize/addCompanySize",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post(`/admin/researchMeta/createCompanySize`, params)
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }




  }
);

export const updateCompanySize = createAsyncThunk(
  "companySize/updateCompanySize",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .patch(`/admin/researchMeta/editCompanySize/${params.id}`, {
          companySize: params.companySize,
        })
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }



  }
);

export const deleteCompanySize = createAsyncThunk(
  "companySize/deleteCompanySize",
  async (id, thunkAPI) => {
    try {

      const response = await secureApi
        .delete(`/admin/researchMeta/deleteCompanySize/${id}`)
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }


  }
);
