import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getIndustries = createAsyncThunk(
  "industry/getIndustries",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .get(`/admin/researchMeta/getAllIndustries`)
      return response.data;
    } catch (error) {
      return { ...error, status: false }
    }
  }
);

export const addIndustry = createAsyncThunk(
  "industry/addIndustry",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post(`/admin/researchMeta/createIndustry`, params)
      return response.data
    } catch (error) {

      return { ...error, status: false }
    }


  }
);

export const updateIndustry = createAsyncThunk(
  "industry/updateIndustry",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .patch(`/admin/researchMeta/editIndustry/${params.id}`, {
          industry: params.industry,
        })
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }
  }
);

export const deleteIndustry = createAsyncThunk(
  "industry/deleteIndustry",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .delete(`/admin/researchMeta/deleteIndustry/${id}`)
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }
  }
);
