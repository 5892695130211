import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  getJobTitle,
  addJobTitle,
  updateJobTitle,
  deleteJobTitle,
} from "./jobTitleThunk";

const initialState = {
  getJobTitleRes: { status: "", data: null },
  addJobTitleRes: { status: "", data: null },
  updateJobTitleRes: { status: "", data: null },
  deleteJobTitleRes: { status: "", data: null },
};

export const jobTitleSlice = createSlice({
  name: "jobTitle",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getJobTitle.pending.toString(), (state) => {
      return {
        ...state,
        getJobTitleRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getJobTitle.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getJobTitleRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getJobTitle.rejected.toString(), (state) => {
      return {
        ...state,
        getJobTitleRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addJobTitle.pending.toString(), (state) => {
      return {
        ...state,
        addJobTitleRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addJobTitle.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addJobTitleRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(addJobTitle.rejected.toString(), (state) => {
      return {
        ...state,
        addJobTitleRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateJobTitle.pending.toString(), (state) => {
      return {
        ...state,
        updateJobTitleRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updateJobTitle.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateJobTitleRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(updateJobTitle.rejected.toString(), (state) => {
      return {
        ...state,
        updateJobTitleRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
    builder.addCase(deleteJobTitle.pending.toString(), (state) => {
      return {
        ...state,
        deleteJobTitleRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteJobTitle.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteJobTitleRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(deleteJobTitle.rejected.toString(), (state) => {
      return {
        ...state,
        deleteJobTitleRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
