import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  getMapping,
  addMapping,
  editMapping,
  deleteMapping,
  getSingleMapping,
} from "./mappingThunk";

const initialState = {
  getMappingRes: { status: "", data: null },
  addMappingRes: { status: "", data: null },
  deleteMappingRes: { status: "", data: null },
  editMappingRes: { status: "", data: null },
  getSingleMappingRes: { status: "", data: null },
};

export const mappingSlice = createSlice({
  name: "mapping",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getMapping.pending.toString(), (state) => {
      return {
        ...state,
        getMappingRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getMapping.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getMappingRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getMapping.rejected.toString(), (state) => {
      return {
        ...state,
        getMappingRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getSingleMapping.pending.toString(), (state) => {
      return {
        ...state,
        getSingleMappingRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getSingleMapping.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getSingleMappingRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getSingleMapping.rejected.toString(), (state) => {
      return {
        ...state,
        getSingleMappingRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addMapping.pending.toString(), (state) => {
      return {
        ...state,
        addMappingRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addMapping.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addMappingRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(addMapping.rejected.toString(), (state) => {
      return {
        ...state,
        addMappingRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(editMapping.pending.toString(), (state) => {
      return {
        ...state,
        editMappingRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(editMapping.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        editMappingRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(editMapping.rejected.toString(), (state) => {
      return {
        ...state,
        editMappingRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(deleteMapping.pending.toString(), (state) => {
      return {
        ...state,
        deleteMappingRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(deleteMapping.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteMappingRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(deleteMapping.rejected.toString(), (state) => {
      return {
        ...state,
        deleteMappingRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
