import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const runMatchAlgo = createAsyncThunk(
  "algo/runMatchAlgo",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .post("/admin/survey/runmatchingalgo/", {
          surveyId: id,
        })
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const getRespondentHistoryForSurvey = createAsyncThunk(
  "algo/getRespondentHistoryForSurvey",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .post("/admin/livesurvey/getRespondentHistoryForSurvey", {
          surveyId: id,
        })
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);
