import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getSurveyFeedback = createAsyncThunk(
  "surveyFeedback/getSurveyFeedback",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .get(`/admin/feedback/getall/${id}`)
        .catch((e) => {
          return e;
        });
      return response.data;

    } catch (error) {
      return { ...error, status: false }
    }


  }
);
