import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getMapping = createAsyncThunk(
  "mapping/getMapping",
  async (thunkAPI) => {
    try {
      const response = await secureApi.get("/admin/mapping/").catch((e) => {
        return e;
      });
      return response.data;
    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const getSingleMapping = createAsyncThunk(
  "mapping/getSingleMapping",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .get(`/admin/onBoardingQuestions/${id}`)
      return response.data;
    } catch (error) {
      return { ...error, status: false }
    }

  }
);

export const addMapping = createAsyncThunk(
  "mapping/addMapping",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post("/admin/mapping/", params)
      return response.data;
    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const editMapping = createAsyncThunk(
  "mapping/editMapping",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .patch(`/admin/mapping/${params.id}`, params)
      return response.data;
    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const deleteMapping = createAsyncThunk(
  "mapping/deleteMapping",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .delete(`/admin/mapping/${id}`)
      return response.data;
    } catch (error) {
      return { ...error, status: false }
    }



  }
);
