import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getJobTitle = createAsyncThunk(
  "jobTitle/getJobTitle",
  async (params, thunkAPI) => {

    try {
      const response = await secureApi
        .get(`/admin/researchMeta/getAllJobTitle`)
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }




  }
);

export const addJobTitle = createAsyncThunk(
  "jobTitle/addJobTitle",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post(`/admin/researchMeta/createJobTitle`, params)
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }



  }
);

export const updateJobTitle = createAsyncThunk(
  "jobTitle/updateJobTitle",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .patch(`/admin/researchMeta/editJobTitle/${params.id}`, {
          jobTitle: params.jobTitle,
        })
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const deleteJobTitle = createAsyncThunk(
  "jobTitle/deleteJobTitle",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .delete(`/admin/researchMeta/deleteJobTitle/${id}`)
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }


  }
);
