import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  getAllRespondents,
  sendNotice,
  changeRespondentPassword,
  getFilledSurveys,
  changeRespondentStatus,
  getFinalRespondents,
  getCompletedResearchProjects,
  getRespondentProfile,
  getMetaForRespondent,
} from "./respondentsThunk";

const initialState = {
  getAllRespondentsRes: { status: "", data: null },
  sendNoticeRes: { status: "", data: null },
  changeRespondentPasswordData: { status: "", data: null },
  getFilledSurveysRes: { status: "", data: null },
  changeRespondentStatusRes: { status: "", data: null },
  getFinalRespondentsRes: { status: "", data: null },
  getCompletedResearchProjectsRes: { status: "", data: null },
  getRespondentProfileRes: { status: "", data: null },
  getMetaForRespondentRes: { status: "", data: null },
};

export const respondentsSlice = createSlice({
  name: "respondents",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getAllRespondents.pending.toString(), (state) => {
      return {
        ...state,
        getAllRespondentsRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getAllRespondents.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getAllRespondentsRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getAllRespondents.rejected.toString(), (state) => {
      return {
        ...state,
        getAllRespondentsRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(sendNotice.pending.toString(), (state) => {
      return {
        ...state,
        sendNoticeRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(sendNotice.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        sendNoticeRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(sendNotice.rejected.toString(), (state, action) => {
      return {
        ...state,
        sendNoticeRes: { status: APIstatus.FAILURE, data: action.payload },
      };
    });

    builder.addCase(changeRespondentPassword.pending.toString(), (state) => {
      return {
        ...state,
        changeRespondentPasswordData: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      changeRespondentPassword.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          changeRespondentPasswordData: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(changeRespondentPassword.rejected.toString(), (state) => {
      return {
        ...state,
        changeRespondentPasswordData: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getFilledSurveys.pending.toString(), (state) => {
      return {
        ...state,
        getFilledSurveysRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(getFilledSurveys.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getFilledSurveysRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getFilledSurveys.rejected.toString(), (state) => {
      return {
        ...state,
        getFilledSurveysRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(changeRespondentStatus.pending.toString(), (state) => {
      return {
        ...state,
        changeRespondentStatusRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      changeRespondentStatus.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          changeRespondentStatusRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(changeRespondentStatus.rejected.toString(), (state) => {
      return {
        ...state,
        changeRespondentStatusRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getFinalRespondents.pending.toString(), (state) => {
      return {
        ...state,
        getFinalRespondentsRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getFinalRespondents.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getFinalRespondentsRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getFinalRespondents.rejected.toString(), (state) => {
      return {
        ...state,
        getFinalRespondentsRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getRespondentProfile.pending.toString(), (state) => {
      return {
        ...state,
        getRespondentProfileRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getRespondentProfile.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getRespondentProfileRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getRespondentProfile.rejected.toString(), (state) => {
      return {
        ...state,
        getRespondentProfileRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getMetaForRespondent.pending.toString(), (state) => {
      return {
        ...state,
        getMetaForRespondentRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getMetaForRespondent.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getMetaForRespondentRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getMetaForRespondent.rejected.toString(), (state) => {
      return {
        ...state,
        getMetaForRespondentRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(
      getCompletedResearchProjects.pending.toString(),
      (state) => {
        return {
          ...state,
          getCompletedResearchProjectsRes: {
            status: APIstatus.IN_PROGRESS,
            data: [],
          },
        };
      }
    );
    builder.addCase(
      getCompletedResearchProjects.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getCompletedResearchProjectsRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(
      getCompletedResearchProjects.rejected.toString(),
      (state) => {
        return {
          ...state,
          getCompletedResearchProjectsRes: {
            status: APIstatus.FAILURE,
            data: [],
          },
        };
      }
    );
  },
});
