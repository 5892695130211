import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getJobFunction = createAsyncThunk(
  "jobFunction/getJobFunction",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .get(`/admin/researchMeta/getAllJobFunction`)
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }


  }
);

export const addJobFunction = createAsyncThunk(
  "jobFunction/addJobFunction",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post(`/admin/researchMeta/createJobFunction`, params)
      return response.data;
    } catch (error) {
      return { ...error, status: false }
    }



  }
);

export const updateJobFunction = createAsyncThunk(
  "jobFunction/updateJobFunction",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .patch(`/admin/researchMeta/editJobFunction/${params.id}`, {
          jobFunction: params.jobFunction,
        })
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }



  }
);

export const deleteJobFunction = createAsyncThunk(
  "jobFunction/deleteJobFunction",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .delete(`/admin/researchMeta/deleteJobFunction/${id}`);
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }



  }
);
