import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getContacts = createAsyncThunk(
  "contacts/getContacts",
  async (thunkAPI) => {
    try {

      const response = await secureApi.get("/admin/contactUs/").catch((e) => {
        return e;
      });
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }

  }
);

export const deleteContact = createAsyncThunk(
  "contacts/deleteContact",
  async (id, thunkAPI) => {
    try {

      const response = await secureApi
        .delete(`/admin/contactUs/${id}`)
      return response.data
    } catch (error) {
      return { ...error, status: false }
    }


  }
);
