import { createAsyncThunk } from "@reduxjs/toolkit";
import secureApi from "../../../auth/FetchInterceptor";

export const getSkill = createAsyncThunk(
  "skill/getSkill",
  async (params, thunkAPI) => {
    const response = await secureApi
      .get(`/admin/researchMeta/getAllSkill`)
      .catch((e) => {
        return e;
      });
    if (response.data) {
      return response.data;
    } else {
      return thunkAPI.rejectWithValue(response.data);
    }
  }
);

export const addSkill = createAsyncThunk(
  "skill/addSkill",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .post(`/admin/researchMeta/createSkill`, params)

      return response.data
    } catch (error) {
      return { ...error, status: false }
    }
  }
);

export const updateSkill = createAsyncThunk(
  "skill/updateSkill",
  async (params, thunkAPI) => {
    try {
      const response = await secureApi
        .patch(`/admin/researchMeta/editSkill/${params.id}`, {
          skill: params.skill,
        })

      return response.data
    } catch (error) {
      return { ...error, status: false }
    }
  }
);

export const deleteSkill = createAsyncThunk(
  "skill/deleteSkill",
  async (id, thunkAPI) => {
    try {
      const response = await secureApi
        .delete(`/admin/researchMeta/deleteSkill/${id}`)

      return response.data
    } catch (error) {
      return { ...error, status: false }
    }
  }
);
