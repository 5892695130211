import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  getOrganization,
  updateOrganizationStatus,
  getOrganiztionUsers,
  getSurveyByOrganiztionId,
  getSurveyByUserId,
  updateOrganizationSurveyStatus,
  getSurveyDataById,
} from "./organizationThunk";

const initialState = {
  organizationListRes: { status: "", data: null },
  updateOrgStatusRes: { status: null },
  organiztionUsersRes: { status: "", data: null },
  surveyByOrganiztionIdList: { status: "", data: null },
  getSurveyByUserIdRes: { status: "", data: null },
  updateOrganizationSurveyStatusRes: { status: "", data: null },
  getSurveyDataByIdRes: { status: "", data: null },
};

export const organizationSlice = createSlice({
  name: "organization",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getOrganization.pending.toString(), (state) => {
      return {
        ...state,
        organizationListRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getOrganization.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        organizationListRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getOrganization.rejected.toString(), (state) => {
      return {
        ...state,
        organizationListRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateOrganizationStatus.pending.toString(), (state) => {
      return {
        ...state,
        updateOrgStatusRes: { status: APIstatus.IN_PROGRESS },
      };
    });
    builder.addCase(
      updateOrganizationStatus.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          updateOrgStatusRes: {
            status: APIstatus.SUCCESS,
            data:action.payload
          },
        };
      }
    );
    builder.addCase(updateOrganizationStatus.rejected.toString(), (state) => {
      return {
        ...state,
        updateOrgStatusRes: { status: APIstatus.FAILURE },
      };
    });

    builder.addCase(getOrganiztionUsers.pending.toString(), (state) => {
      return {
        ...state,
        organiztionUsersRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getOrganiztionUsers.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          organiztionUsersRes: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getOrganiztionUsers.rejected.toString(), (state) => {
      return {
        ...state,
        organiztionUsersRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getSurveyByOrganiztionId.pending.toString(), (state) => {
      return {
        ...state,
        surveyByOrganiztionIdList: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(
      getSurveyByOrganiztionId.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          surveyByOrganiztionIdList: {
            status: APIstatus.SUCCESS,
            data: action.payload.data,
          },
        };
      }
    );
    builder.addCase(getSurveyByOrganiztionId.rejected.toString(), (state) => {
      return {
        ...state,
        surveyByOrganiztionIdList: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(getSurveyByUserId.pending.toString(), (state) => {
      return {
        ...state,
        getSurveyByUserIdRes: {
          status: APIstatus.IN_PROGRESS,
          data: [],
        },
      };
    });
    builder.addCase(getSurveyByUserId.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getSurveyByUserIdRes: {
          status: APIstatus.SUCCESS,
          data: action.payload.data,
        },
      };
    });
    builder.addCase(getSurveyByUserId.rejected.toString(), (state) => {
      return {
        ...state,
        getSurveyByUserIdRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(
      updateOrganizationSurveyStatus.pending.toString(),
      (state) => {
        return {
          ...state,
          updateOrganizationSurveyStatusRes: { status: APIstatus.IN_PROGRESS },
        };
      }
    );
    builder.addCase(
      updateOrganizationSurveyStatus.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          updateOrganizationSurveyStatusRes: {
            status: APIstatus.SUCCESS,
            data: action.payload
          },
        };
      }
    );
    builder.addCase(
      updateOrganizationSurveyStatus.rejected.toString(),
      (state) => {
        return {
          ...state,
          updateOrganizationSurveyStatusRes: { status: APIstatus.FAILURE },
        };
      }
    );

    builder.addCase(getSurveyDataById.pending.toString(), (state) => {
      return {
        ...state,
        getSurveyDataByIdRes: { status: APIstatus.IN_PROGRESS },
      };
    });
    builder.addCase(getSurveyDataById.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getSurveyDataByIdRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getSurveyDataById.rejected.toString(), (state) => {
      return {
        ...state,
        getSurveyDataByIdRes: { status: APIstatus.FAILURE },
      };
    });
  },
});
