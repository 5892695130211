import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";

import {
  getEmploymentStatus,
  addEmploymentStatus,
  updateEmploymentStatus,
  deleteEmploymentStatus,
} from "./employmentStatusThunk";

const initialState = {
  getEmploymentStatusRes: { status: "", data: null },
  addEmploymentStatusRes: { status: "", data: null },
  updateEmploymentStatusRes: { status: "", data: null },
  deleteEmploymentStatusRes: { status: "", data: null },
};

export const employmentStatusSlice = createSlice({
  name: "employmentStatus",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getEmploymentStatus.pending.toString(), (state) => {
      return {
        ...state,
        getEmploymentStatusRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      getEmploymentStatus.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          getEmploymentStatusRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(getEmploymentStatus.rejected.toString(), (state) => {
      return {
        ...state,
        getEmploymentStatusRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addEmploymentStatus.pending.toString(), (state) => {
      return {
        ...state,
        addEmploymentStatusRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      addEmploymentStatus.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          addEmploymentStatusRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(addEmploymentStatus.rejected.toString(), (state) => {
      return {
        ...state,
        addEmploymentStatusRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateEmploymentStatus.pending.toString(), (state) => {
      return {
        ...state,
        updateEmploymentStatusRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      updateEmploymentStatus.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          updateEmploymentStatusRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(updateEmploymentStatus.rejected.toString(), (state) => {
      return {
        ...state,
        updateEmploymentStatusRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
    builder.addCase(deleteEmploymentStatus.pending.toString(), (state) => {
      return {
        ...state,
        deleteEmploymentStatusRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(
      deleteEmploymentStatus.fulfilled.toString(),
      (state, action) => {
        return {
          ...state,
          deleteEmploymentStatusRes: {
            status: APIstatus.SUCCESS,
            data: action.payload,
          },
        };
      }
    );
    builder.addCase(deleteEmploymentStatus.rejected.toString(), (state) => {
      return {
        ...state,
        deleteEmploymentStatusRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
