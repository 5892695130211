import { createSlice } from "@reduxjs/toolkit";
import APIstatus from "../../constants/common";
import {
  getIndustries,
  addIndustry,
  updateIndustry,
  deleteIndustry,
} from "./industryThunk";

const initialState = {
  addIndustryRes: { status: "", data: null },
  getIndustriesRes: { status: "", data: null },
  updateIndustryRes: { status: "", data: null },
  deleteIndustryRes: { status: "", data: null },
};

export const industrySlice = createSlice({
  name: "industry",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getIndustries.pending.toString(), (state) => {
      return {
        ...state,
        getIndustriesRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(getIndustries.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        getIndustriesRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(getIndustries.rejected.toString(), (state) => {
      return {
        ...state,
        getIndustriesRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(addIndustry.pending.toString(), (state) => {
      return {
        ...state,
        addIndustryRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(addIndustry.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        addIndustryRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(addIndustry.rejected.toString(), (state) => {
      return {
        ...state,
        addIndustryRes: { status: APIstatus.FAILURE, data: [] },
      };
    });

    builder.addCase(updateIndustry.pending.toString(), (state) => {
      return {
        ...state,
        updateIndustryRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(updateIndustry.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        updateIndustryRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(updateIndustry.rejected.toString(), (state) => {
      return {
        ...state,
        updateIndustryRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
    builder.addCase(deleteIndustry.pending.toString(), (state) => {
      return {
        ...state,
        deleteIndustryRes: { status: APIstatus.IN_PROGRESS, data: [] },
      };
    });
    builder.addCase(deleteIndustry.fulfilled.toString(), (state, action) => {
      return {
        ...state,
        deleteIndustryRes: {
          status: APIstatus.SUCCESS,
          data: action.payload,
        },
      };
    });
    builder.addCase(deleteIndustry.rejected.toString(), (state) => {
      return {
        ...state,
        deleteIndustryRes: { status: APIstatus.FAILURE, data: [] },
      };
    });
  },
});
